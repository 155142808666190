import React from "react"
import SEO from "../components/seo"
import { useIntl } from "gatsby-plugin-intl"
import { graphql } from "gatsby"

const Resume = ({ data }) => {
  const intl = useIntl()

  return (
    <>
      <SEO title={intl.formatMessage({ id: "resume" })} />
      <div className="container">
        <div className="row align-content-center align-items-center">
          <div className="col-12 text-center">
            <div
              className="resume-page-content"
              dangerouslySetInnerHTML={{
                __html: data[intl.locale].resume.childContentfulRichText.html,
              }}
            />
          </div>
        </div>
      </div>
    </>
  )
}

export const query = graphql`
  {
    en: contentfulSetting(node_locale: { eq: "en-US" }) {
      resume {
        childContentfulRichText {
          html
        }
      }
    }
    fa: contentfulSetting(node_locale: { eq: "fa" }) {
      resume {
        childContentfulRichText {
          html
        }
      }
    }
  }
`

export default Resume
